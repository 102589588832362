
<template>
  <div>
    <v-row v-if="card">
      <v-col
        v-for="(language, index) in Languages"
        :key="index"
      >
        <v-card
          flat
          outlined
          @click.stop="() => {setLanguage(language.name)}"
          hover
          max-width="120"
          class="center"
        >
          <v-card-text>
            <v-img
              max-height="50"
              max-width="50"
              :src="language.image"
              class="center"
            />
            <div class="text-center mt-2 text-button">
              {{ language.label }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-btn-toggle
      v-else
      v-model="selectedLanguage"
      mandatory
      :tile="tile"
      :group="tile"
      :class="label ? 'onboarding' : ''"
    >
      <v-btn
        v-for="(language, index) in Languages"
        :small="small && !xSmall"
        :x-small="xSmall"
        :width="label ? 110 : null"
        :value="language.name"
        @click.stop="click"
        :key="index"
      >
        <v-img
          max-height="17"
          max-width="17"
          :src="language.image"
        />

        <span
          v-if="label"
          class="ml-2"
        >
          {{ language.label }}
        </span>
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import flagFr from "@/assets/images/flag-france.png"
import flagEn from "@/assets/images/flag-united-kingdom.png"

export default {
  name: "LanguageSwitch",
  props: {
    value: {
      type: String,
      default: null,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    label: {
      type: Boolean,
      default: false,
    },
    card: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: true,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  computed: {
    selectedLanguage: {
      get() { return this.value },
      set(val, oldVal) {
        console.log(val, oldVal)
        if(oldVal) return
        this.$emit("input", val)
        this.$emit("change", val)

      },
    },
    Languages() {
      return [{
        name: "fr",
        image: flagFr,
        label: "Français",
      }, {
        name: "en",
        image: flagEn,
        label: "English",
      }]
    },
  },
  methods: {
    setLanguage(language) {
      this.selectedLanguage = language
      this.click()
    },
    click() {
      this.$emit("click")
    },
  },
}
</script>

<style scoped>
  .onboarding {
    margin-left: 25%;
  }
</style>
